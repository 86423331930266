import { keepPreviousData } from '@tanstack/react-query';
import type { IncomingMessage } from 'http';
import type { ParsedUrlQuery } from 'querystring';
import { createInfiniteQuery } from 'react-query-kit';
import type { GetArticlesParams } from '../../api/ArticlesApiClient';
import { ArticlesApiClient } from '../../api/ArticlesApiClient';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import {
  getNextPageParam,
  getPreviousPageParam,
} from '../../utilities/articles-pagination/articles-pagination';
import { articlesToSkip } from '../../utilities/articles-to-skip/articles-to-skip';

type UseGetArticlesVariables = Omit<GetArticlesParams, 'skip' | 'take'> & {
  slug: string;
};

export const useGetArticles = createInfiniteQuery({
  queryKey: ['get-articles'],
  fetcher: (variables: UseGetArticlesVariables, { pageParam, meta }) => {
    const articlesApiClient = new ArticlesApiClient({ headers: meta?.headers });

    return articlesApiClient.getArticlesBySlug(variables.slug, {
      ...variables,
      take: pageParam?.take,
      skip: pageParam?.skip,
    });
  },
  initialPageParam: { take: DEFAULT_PAGE_SIZE, skip: 0 },
  getNextPageParam,
  getPreviousPageParam,
  staleTime: Infinity,
  placeholderData: keepPreviousData,
});

type PrefetchUseGetArticlesQueryParams = {
  variables: UseGetArticlesVariables;
  page: ParsedUrlQuery['page'];
  req: IncomingMessage;
};

export const prefetchUseGetArticlesQuery = ({
  variables: params,
  page,
  req,
}: PrefetchUseGetArticlesQueryParams) => {
  // we need to know if we are prefetching for a SPA request or a server request (e.g. .json is a SPA request)
  // we do this to use pages instead to get the correct amount of articles on the screen (this is because to keep the scroll position when navigating back for example)
  const isRequestedAsSpaRoute = (req?.url?.match(/.json/)?.length ?? 0) >= 1;

  const variables = {
    ...params,
  } satisfies UseGetArticlesVariables;

  // we remove sortBy if it is undefined, otherwise it we will get an error while because of JSON serialization
  if (variables.sortBy === undefined) {
    delete variables.sortBy;
  }

  return {
    ...useGetArticles.getFetchOptions(variables),
    queryFn: ({ pageParam }: { pageParam: Required<Pick<GetArticlesParams, 'skip' | 'take'>> }) =>
      useGetArticles.fetcher(variables, { meta: { headers: req.headers }, pageParam }),
    initialPageParam: !isRequestedAsSpaRoute
      ? { take: DEFAULT_PAGE_SIZE, skip: articlesToSkip(page) }
      : { take: DEFAULT_PAGE_SIZE, skip: 0 },
    // pages here will fetch from 1 up to the number of pages (e.g. page=3 will be 1, 2, 3).
    // That's why we have to "reset" the initialPageParam too since it's going to start at page 1 and make three requests to get the correct amount of articles on the screen
    // the "pages" concept is only used for SPA requests and mainly to keep the scroll position when navigating back
    pages: isRequestedAsSpaRoute ? Number(page) || undefined : undefined,
  };
};
