import { createQuery } from 'react-query-kit';
import type { GetFilterAndSortingOptionsParams } from '../../api/ArticlesApiClient';
import { ArticlesApiClient } from '../../api/ArticlesApiClient';

type UseGetFilterAndSortingOptionsVariables = Pick<
  GetFilterAndSortingOptionsParams,
  'byResourceType'
> & {
  slug: string;
};

export const useGetFilterAndSortingOptions = createQuery({
  queryKey: ['get-articles-filter-and-sorting-options'],
  fetcher: (variables: UseGetFilterAndSortingOptionsVariables, { meta }) => {
    const articlesApiClient = new ArticlesApiClient({ headers: meta?.headers });

    return articlesApiClient.getFilterAndSortingOptionsBySlug(variables.slug, {
      ...variables,
    });
  },

  staleTime: Infinity,
});
